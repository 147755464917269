<template>
	<div class="partyBuilding">
		<div class="banner-img">
			<img :src="require('@/assets/img/banner-img.png')"/>
		</div>
<!-- 		<div class="bannerBox">
			<p>{{newsList[0].title}}</p>
		</div> -->
		<div style="margin-top:50px"></div>
		<div class="human-continar">

			<TitleMore :title="['要闻']" @handleMore="selfhandleMore('要闻','ztjy_yw')"></TitleMore>
			<div class="projectactive">
				<div class="projectcontent">
					<div class="activebanners">
						<div class="block">
							<el-carousel trigger="click" height="480px">
								<el-carousel-item v-for="item in imgList" :key="item.id">
									<!-- <h3 class="small">{{ item }}</h3> -->
									<img style="width:100%;height:100%" @click="$goto('/detailShow', { id: item.id })"
										:src="item.smallImage" alt="" />
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="mask"></div>
					</div>
					<div class="activenews">
						<ul>
							<li v-for="(item) in newsList" :key="item.id" @click="$goto('/detailShow', { id: item.id })">
								<p>
									<i>{{ item.title }}</i>
								</p>
								<span>{{ item.publishDate | formateData }}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div style="margin-top:50px"></div>
			<TitleMore :title="['重要论述']" @handleMore="selfhandleMore('重要论述','ztjy_zyls')"></TitleMore>
			<div class="zyls-box">
				<div class="zyls-item" v-for="item in ListData" :key="item.id">
					<div class="zyls-title">{{item.title}}</div>
					<div class="zyls-content">{{item.metaDescription}}</div>
					<div class="view" @click="$goto('/detailShow',{id:item.id})">[查看详情]</div>
				</div>
				<!-- <div class="itemText" style="margin-bottom: 10px;" v-for="item in ListData" :key="item.id"
					@click="$goto('/detailShow',{id:item.id})">
					<p>
						<img src="../../assets/img/list-little.png" alt="">
						<span class="itemTextContent">{{item.title}}</span>
					</p>
					<p class="itemTextTime">{{item.publishDate}}</p>
				</div> -->
				
			</div>
			
			
			<div style="margin-top:50px"></div>
			<TitleMore :title="['工作动态']" @handleMore="selfhandleMore('工作动态','ztjy_gzdt')"></TitleMore>
			<div class="baseCard">
				<div class="itemText" style="margin-bottom: 10px;" v-for="item in gzdtList" :key="item.id"
					@click="$goto('/detailShow',{id:item.id})">
					<p>
						<img src="../../assets/img/list-little.png" alt="">
						<span class="itemTextContent">{{item.title}}</span>
					</p>
					<p class="itemTextTime">{{item.publishDate}}</p>
				</div>
				<!-- <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination> -->
			</div>
			
		</div>
	</div>
</template>

<script>
	import TitleMore from "@components/TitleMore";
	import apiUrls from "@api";
	export default {
		components: {
			TitleMore
		},
		props: {},
		data() {
			return {
				bannerArr: [],
				imgList: [],
				newsList: [],
				activelist: [],
				page: 1,
				size: 10,
				total: 0,
				ListData: [],
				gzdtList:[]
			}
		},
		watch: {},
		computed: {},
		methods: {
			selfhandleMore(title, node) {
				this.$router.push({
					path: '/imgDetail',
					query: {
						name: title,
						type: node
					}
				});
			},
			handleTab(index) {
				switch (index) {
					case 0:
						this.getactivelist('wwqzbss')
						break;
				}
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			getBanner() {
				apiUrls.homeBanner({
					type: "ersd"
				}).then((res) => {
					this.bannerArr = res.results;
				});
			},
			getList() {
				apiUrls
					.selfDynamicList({
						node: "ztjy_zyls",
						page: this.page - 1,
						size: this.size
					})
					.then((res) => {
						this.ListData = res.results.data;
						this.total = res.results.pager.totalElements;
					});
			},
			getGzdt() {
				apiUrls
					.selfDynamicList({
						node: "ztjy_gzdt",
						page: this.page - 1,
						size: this.size
					})
					.then((res) => {
						this.gzdtList = [...res.results.data];
					});
			},
			getactivelist(type) {
				apiUrls.gethumanitarian({
					node: type
				}).then(res => {
					this.activelist = res.results.data;
					this.setData()
				})
			},
			setData() {
				this.$nextTick(function() {
					let _this = this;
					_this.newsList = [];
					_this.imgList = [];
					this.activelist.forEach((item, ind) => {
						//显示所有的新闻
						_this.newsList.push(item)
						if (_this.imgList.length < 3 && item.smallImage) {
							_this.imgList.push(item)
						}
					})
				});
			},
		},
		created() {},
		mounted() {
			this.getBanner()
			this.getList();
			this.getGzdt();
			this.getactivelist('ztjy_yw')
		}
	}
</script>
<style lang="less" scoped>
	.banner-img{
		text-align: center;
		img{
			width: 100%;
		}
	}
	.zyls-box{
		display: flex;
		grid-gap: 40px;
		flex-wrap: wrap;
		padding: 20px 30px;
		background-color: #ffffff;
		box-shadow: 0px 0px 5px 5px #e9e9e9;
		.zyls-title{
			font-size: 20px;
			color: #333;
			font-weight: bold;
			margin-bottom: 20px;
		}
		.zyls-content{
			text-indent: 2em;
			color: #666;
			margin-bottom: 20px;
			font-size: 16px;
		}
		>div{
			width: calc(50% - 20px);
			border-bottom: 1px solid #ccc;
			padding-bottom: 15px;
			.view{
				color: red;
				cursor: pointer;
				font-size: 14px;
			}
		}
	}
	/deep/.titleMoreTitle{
		.tmTitle{
			color: red;
		}
	}
	.bannerBox {
		width: 1200px;
		margin: 0px auto 0;
		margin-top: 20px;
		p{
			font-size: 32px;
			color: red;
			text-align: center;
			font-weight: bold;
			line-height: 48px;
		}
		
	}

	.projectactive {
		box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
	}

	.human-continar {
		width: 1200px;
		min-height: 400px;
		padding-bottom: 40px;
		margin: 0 auto;
		background-color: #f5f5f5;

		&::before {
			display: block;
			content: ".";
			clear: both;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
	}

	.title {
		margin-top: 53px;
		font-size: 22px;
		color: #333333;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		margin-bottom: 16px;

		span {
			&:nth-child(1) {
				display: flex;

				&::before {
					content: '';
					display: block;
					height: 20px;
					width: 20px;
					background: url('../../assets/img/title.png') no-repeat center center;
				}
			}

			&:nth-child(2) {
				color: #ababab;
				font-size: 17px;
			}
		}
	}

	.projectcontent {
		padding: 20px;
		// box-sizing: border-box;
		height: 480px;
		display: flex;
		background-color: #ffffff;

		.activebanners {

			position: relative;
			width: 646px;
			height: 480px;
			background-color: skyblue;

			.el-carousel__item h3 {
				color: #475669;
				font-size: 14px;
				opacity: 0.75;
				line-height: 150px;
				margin: 0;
			}

			.el-carousel__item:nth-child(2n) {
				background-color: #99a9bf;
			}

			.el-carousel__item:nth-child(2n + 1) {
				background-color: #d3dce6;
			}

			.mask {
				position: absolute;
				bottom: 0px;
				left: 0px;
				width: 645px;
				height: 40px;
				// background: #000000;
				opacity: 0.35;
				z-index: 2;
			}
		}

		.activenews {
			flex: 1;
			//    background-color: pink;
			padding: 0 23px;

			li {
				cursor: pointer;
				display: flex;
				justify-content: space-between;

				padding: 10px 0;
				margin-bottom: 10px;

				p {
					display: flex;
					color: #333333;

					&:hover {
						color: #d1292e;
					}

					font-size: 16px;

					&::before {
						content: '';
						display: block;
						width: 16px;
						height: 16px;
						background: url('../../assets/img/list-little.png') no-repeat center center;
					}

					i {
						display: inline-block;
						width: 331px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}

				span {
					color: #ababab;
					font-size: 16px;
				}
			}
		}
	}
</style>